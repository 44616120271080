"use client";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";
import { useMagic } from "@/context/MagicProvider";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import LoadingDots from "../../Loading/LoadingDots";
import { create } from "zustand";
import IconWallet from "../../Icons/IconWallet";
import Login from "@/components/magic/auth/Login";
import Modal from "../../Modal";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { getBasicInfo } from "@/server/actions/user/getBasicInfoJWT";

// Draw attention to the network select btn
interface NetworkSelectHighlightState {
  highlightBtn: boolean;
  setHighlightBtn: (highlightBtn: boolean) => void;
}

export const useNetworkSelectHighlightStore =
  create<NetworkSelectHighlightState>((set) => ({
    highlightBtn: false,
    setHighlightBtn: (highlightBtn) => set({ highlightBtn }),
  }));

type Props = {
  wallet?: string;
};

export default function BtnWalletLogin({ wallet }: Props) {
  const { magic, currentChainId, switchNetwork } = useMagic();
  const [loading, setLoading] = useState(false);

  const networks = [
    {
      label: "Ethereum",
      value: ETHEREUM_MAINNET.chainId,
      image: `/network/${ETHEREUM_MAINNET.chainId}.png`,
      rpcUrl: ETHEREUM_MAINNET.rpcUrl,
    },
    {
      label: "Polygon",
      value: POLYGON_MAINNET.chainId,
      image: `/network/${POLYGON_MAINNET.chainId}.png`,
      rpcUrl: POLYGON_MAINNET.rpcUrl,
    },

    {
      label: "Arbitrum",
      value: ARBITRUM_MAINNET.chainId,
      image: `/network/${ARBITRUM_MAINNET.chainId}.png`,
      rpcUrl: ARBITRUM_MAINNET.rpcUrl,
    },
    {
      label: "Optimism",
      value: OPTIMISM_MAINNET.chainId,
      image: `/network/${OPTIMISM_MAINNET.chainId}.png`,
      rpcUrl: OPTIMISM_MAINNET.rpcUrl,
    },
    {
      label: "Base",
      value: BASE_MAINNET.chainId,
      image: `/network/${BASE_MAINNET.chainId}.png`,
      rpcUrl: BASE_MAINNET.rpcUrl,
    },
  ];

  const [selected, setSelected] = useState(networks[0]);

  const handleNetworkChange = async (selectedItem) => {
    if (selectedItem) {
      await switchNetwork(selectedItem.rpcUrl, selectedItem.value);
      setSelected(selectedItem);
    }
  };

  useEffect(() => {
    const selectedNetwork = networks.find((n) => n.value === currentChainId);
    if (selectedNetwork) {
      setSelected(selectedNetwork);
    }
  }, [currentChainId]);

  const { highlightBtn } = useNetworkSelectHighlightStore();

  useEffect(() => {
    if (highlightBtn) {
      setTimeout(() => {
        useNetworkSelectHighlightStore.getState().setHighlightBtn(false);
      }, 6000);
    }
  }, [highlightBtn]);

  const checkUserLoggedIn = async () => {
    const loggedIn = await magic?.user?.isLoggedIn();
    return loggedIn;
  };
  const [login, setLoginModal] = useState(false);

  const handleLoginBtnClick = async () => {
    setLoading(true);
    const info = await getBasicInfo();
    if (!info.userWallet) {
      setLoginModal(true);
      setLoading(false);
      await logoutUser();
      return;
    }
    const loggedIn = await checkUserLoggedIn();
    if (loggedIn) {
      magic?.wallet.showUI();
    } else {
      // Clear basic info cookie if user is not logged in
      await logoutUser();
      setLoginModal(true);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="inline-flex rounded-md shadow-sm group min-w-[100px] ">
        <button
          type="button"
          onClick={handleLoginBtnClick}
          className={`flex h-[49px] flex-grow overflow-clip items-center justify-center  border 
        border-transparent px-4 sm:px-6  bg-primary py-3 text-base font-medium 
        hover:brightness-110 focus:outline-none 
        font-500 transition ${
          wallet && wallet.length > 0
            ? "rounded-l-lg  w-[70px] sm:w-[160px] "
            : "rounded-lg w-[80px] sm:w-[180px] "
        }  ${!wallet && "rounded-lg w-[80px] sm:w-[170px]"}`}
        >
          <>
            {loading ? (
              <LoadingDots />
            ) : (
              <>
                {wallet && wallet.length > 0 ? (
                  <span className="w-full sm:flex items-center justify-center gap-x-3 md:min-w-[100px]">
                    <IconWallet className="max-w-[26px] min-w-[26px]" />
                    <span className="hidden sm:flex min-w-[70px]">
                      My Wallet
                    </span>
                  </span>
                ) : (
                  <span className="flex w-full justify-center">
                    Log in
                    <span className="hidden sm:flex ml-1"> / Sign up</span>
                  </span>
                )}
              </>
            )}
          </>
        </button>
        {wallet && wallet.length > 0 && (
          <Menu as="div" className="relative -ml-px flex items-center">
            <div className="h-[80%] w-[0.75px] bg-dark-pri" />
            <Menu.Button
              className={`h-[49px]  relative inline-flex group items-center hover:brightness-110 rounded-r-md 
            bg-gradient-to-r from-primary to-dark-pri transition hover:bg-dark-pri/20 px-2 py-3 
            focus:z-10 ${
              highlightBtn ? "animate-pulse from-success to-success" : ""
            }`}
            >
              <span className="sr-only">Open blockchain options</span>
              <span className="flex items-center">
                <img
                  src={selected.image}
                  alt=""
                  className="h-5 w-5 min-w-5 object-cover sm:object-contain mr-1 group-hover:scale-105 rounded-full"
                />
              </span>
              <ChevronDownIcon
                className="h-4 w-4 opacity-80 hidden sm:flex"
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-10 -mr-1 mt-[270px] w-56 origin-top-right rounded-lg bg-dark-default shadow-black/60 shadow-lg ring-1 
          ring-dark-pri/10 ring-opacity-5 focus:outline-none border border-dark-pri/10"
              >
                <div className="py-1">
                  {networks.map((item) => (
                    <Menu.Item key={item.label}>
                      <div
                        onClick={() => handleNetworkChange(item)}
                        className=" hover:bg-primary transition text-white 
                         px-4 py-2 text-sm flex cursor-pointer items-center gap-x-3"
                      >
                        <img
                          src={item.image}
                          alt=""
                          className="h-5 w-5 flex-shrink-0 rounded-full "
                        />
                        {item.label}
                      </div>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      <Modal open={login} setOpen={setLoginModal} width="sm:w-[400px] w-full">
        <Login setLoginModal={setLoginModal} />
      </Modal>
    </>
  );
}
