type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  labelBold?: string;
  labelLight?: string | React.ReactNode;
  name?: string;
  children?: React.ReactNode;
  id?: string;
};

export default function Checkbox({
  onChange,
  children,
  labelBold,
  labelLight,
  name,
  checked,
  defaultChecked,
  id,
}: Props) {
  // Toggle checked state directly
  const handleToggle = () => {
    if (onChange) {
      // Simulate a change event object
      onChange({
        target: { checked: !checked, name },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <fieldset>
      <div className="relative flex items-center">
        <div className="flex items-center">
          {/* Hidden real checkbox */}
          <input
            id={id || name}
            aria-describedby={labelBold}
            name={name}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            defaultChecked={defaultChecked}
            className="opacity-0 absolute h-4 w-4 z-10"
          />
          {/* Custom checkbox styling */}
          <div
            className={`h-4 w-4 rounded border-primary border neonPurple transition flex items-center justify-center cursor-pointer ${
              checked ? "bg-primary" : "bg-white"
            }`}
            onClick={handleToggle}
          >
            {checked && (
              <svg
                className="fill-current text-white h-3 w-3"
                viewBox="0 0 20 20"
              >
                <path d="M16.707 5.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L8 12.586l7.293-7.293a1 1 0 0 1 1.414 0z" />
              </svg>
            )}
          </div>
        </div>
        <div className="ml-3 text-sm">
          {children ? (
            children
          ) : (
            <>
              <label
                htmlFor={id || name}
                className="font-medium cursor-pointer"
              >
                {labelBold}{" "}
                <span id="comments-description" className="text-light-gray">
                  {labelLight}
                </span>
              </label>
            </>
          )}
        </div>
      </div>
    </fieldset>
  );
}
