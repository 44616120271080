import Image from "next/image";

type Props = {
  onClick?: () => void;
};

export default function Logo({ onClick }: Props) {
  return (
    <div onClick={onClick}>
      <span
        className={`flex items-center font-600 text-15 ${
          onClick && "cursor-pointer"
        } text-white`}
      >
        <Image
          src="/rodeo-logo.png"
          // src="/r.png"
          alt="Rodeyo Logo"
          width={48}
          height={32}
          className="w-[48px] h-[32px] mr-2 object-contain"
        />
        Rodeyo
      </span>
    </div>
  );
}
