"use client";
import Checkbox from "@/components/ui/Forms/Checkbox";
import Terms from "@/components/ui/Terms";
import { handleUserLogin } from "@/server/actions/user/loginAndReg";
import { saveUserInfo } from "@/utils/common";
import showToast from "@/utils/showToast";
import { RPCError, RPCErrorCode } from "magic-sdk";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useMagic } from "../../../context/MagicProvider";
import PrimaryButton from "../../ui/Forms/Button/PrimaryBtn";
import Logo from "../../ui/Logo";
import TextTitle from "../../ui/Typography/TextTitle";
// import { magic2 } from "../../../lib/magic2";
import SecondaryButton from "@/components/ui/Forms/Button/SecondaryBtn";

type Props = {
  setLoginModal: Dispatch<SetStateAction<boolean>>;
};

const Login = ({ setLoginModal }: Props) => {
  const { magic } = useMagic();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [isLoginInProgress, setLoginInProgress] = useState(false);
  const [newsletter, setNewsletter] = useState(true);

  const handleLogin = async (e?: React.KeyboardEvent | React.MouseEvent) => {
    e?.preventDefault();
    if (
      !email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      setEmailError(true);
    } else {
      try {
        setLoginInProgress(true);
        setEmailError(false);

        const token = await magic?.auth.loginWithEmailOTP({ email });
        const metadata = await magic?.user.getInfo();

        if (!token || !metadata?.publicAddress) {
          throw new Error("Magic login failed");
        }
        saveUserInfo(token, "EMAIL", metadata?.publicAddress);
        await handleUserLogin(token || "", email, newsletter);
        setLoginModal(false);
        setEmail("");
      } catch (e) {
        if (e instanceof RPCError) {
          switch (e.code) {
            case RPCErrorCode.MagicLinkFailedVerification:
            case RPCErrorCode.MagicLinkExpired:
            case RPCErrorCode.MagicLinkRateLimited:
            case RPCErrorCode.UserAlreadyLoggedIn:
              showToast({ message: e.message, type: "error" });
              break;
            default:
              showToast({
                message: "Something went wrong. Please try again",
                type: "error",
              });
          }
        }
      } finally {
        setLoginInProgress(false);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      <div className="mx-auto p-4 mt-8 mb-1 relative flex flex-col items-center justify-center">
        <div className="-z-10 bg-primary/70 blur-[32px] rounded-full absolute top-0 left-1/2 w-[90px] h-[50px]" />
        <Logo />
      </div>
      <TextTitle title="LOG IN" />
      <div className="h-[24px]" />
      <form
        className="grid gap-y-[24px] mt-[36x] mb-[12px]"
        onSubmit={(e) => e.preventDefault()}
      >
        <div>
          <input
            disabled={isLoginInProgress}
            onKeyPress={handleKeyPress}
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              if (emailError) setEmailError(false);
              setEmail(e.target.value);
            }}
            placeholder="you@example.com"
            value={email}
            autoFocus={true}
            className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-3 
              text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri sm:leading-6"
          />
        </div>

        <Checkbox
          name="newsletter-subscribe"
          checked={newsletter}
          onChange={() => setNewsletter(!newsletter)}
        >
          <div
            onClick={() => setNewsletter(!newsletter)}
            className="cursor-pointer text-light-gray text-sm -mt-[0.5] tracking-wide"
          >
            Subscribe to newsletter for exclusive deals.
          </div>
        </Checkbox>
        {emailError && <span className="error">Enter a valid email</span>}

        <PrimaryButton
          onClick={() => handleLogin()}
          loading={isLoginInProgress}
          neon
        >
          Log in / Sign up
        </PrimaryButton>
        {/* <SecondaryButton onClick={handleLogin2}>Click Me</SecondaryButton> */}
      </form>
      <Terms clickText="Login / Sign up" />

      <div className="sm:pb-[32px]" />
    </>
  );
};

export default Login;
