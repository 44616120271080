import Link from "next/link";

type Props = {
  clickText?: string;
};

export default function Terms({ clickText = "Buy Now" }: Props) {
  return (
    <div className="mt-6">
      <p className="m-auto  w-[227px] text-center text-xs leading-[20px] font-500 text-light-gray/90">
        By clicking {clickText}, you agree to our{" "}
        <Link
          className="text-600 text-dark-pri hover:underline"
          href="/policies/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </Link>{" "}
        and{" "}
        <Link
          className="text-600 text-dark-pri hover:underline"
          href="/policies/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </p>
    </div>
  );
}
